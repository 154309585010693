import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['form']

  submit() {
    Rails.fire(this.formTarget, 'submit')
  }
}
