import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "dateForm",
    "dateDisplay"
  ]

  connect() {
    
  }
  
  
  showInvoiceDateForm(e) {
    console.log("show it")
    this.dateDisplayTarget.classList.add("hidden")
    this.dateFormTarget.classList.remove("hidden")
  }

}
