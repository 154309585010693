import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "name", "intentId", "customerId", "accountId" ]

  connect () {
    
    const cardElement = document.querySelector("#card-element")
  
    if (cardElement !== null) { this.setupStripe() }
  
    // Handle users with existing card who would like to use a new one
    const newCard = document.querySelector("#use-new-card")
    if (newCard !== null) {
      newCard.addEventListener("click", (event) => {
        event.preventDefault()
        document.querySelector("#payment-form").classList.remove("hidden")
        document.querySelector("#existing-card").classList.add("hidden")
      })
    }
  }

  setupStripe () {
    const stripeKey = document.querySelector("meta[name='stripe-key']").getAttribute("content")
    this.stripe = Stripe(stripeKey, {stripeAccount: this.accountIdTarget.value});

    const elements = this.stripe.elements();
    
    const style = {

    };
  
    this.card = elements.create("card", { style: style });
    this.card.mount("#card-element");

    this.displayError = document.getElementById('card-element-errors')

    this.card.addEventListener('change', (event) => {
      if (event.error) {
        this.displayError.textContent = event.error.message
      } else {
        this.displayError.textContent = ''
      }
    })
  
    this.form = document.querySelector("#payment-form")

  }

  handleCardForm(event) {
    event.preventDefault()
    
    let name = this.nameTarget.value

    let data = {
      payment_method_data: {
        card: this.card,
        billing_details: {
          name: name,
        }
      }
    }

    this.stripe.confirmCardPayment(this.intentIdTarget.value, {
      payment_method: data.payment_method_data,
      setup_future_usage: 'off_session',
      save_payment_method: true,
    }).then((result) => {
      if (result.error) {
        this.displayError.textContent = result.error.message
      } else {
        this.form.submit()
      }
    })
  }


}
