import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['hours']

  updateHours (e) {
    const selectedDate = e.target.value
    const day = selectedDate.substr(0, selectedDate.indexOf(','))

    Rails.ajax({
      url: `/update_hours?selected_day=${day}`,
      type: 'GET'
    })
  }
}
