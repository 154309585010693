import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'rejectButton']

  toggleForm (e) {
    e.preventDefault()
    this.formTarget.classList.toggle('hidden')
    this.rejectButtonTarget.classList.toggle('hidden')
    e.target.classList.toggle('reject')

    if (e.target.textContent === 'Accept') {
      e.target.textContent = 'Cancel'
    } else {
      e.target.textContent = 'Accept'
    }
  }
}
