import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['view', 'active', 'upcoming', 'failed']

  switchView (e) {
    e.preventDefault()

    this.viewTargets.forEach((element) => {
      element.classList.add('hidden')
    })
  }

  showActive (e) {
    this.switchView(e)
    this.activeTarget.classList.remove('hidden')
    window.currentRequestSection = 'active'
  }

  showUpcoming (e) {
    this.switchView(e)
    this.upcomingTarget.classList.remove('hidden')
    window.currentRequestSection = 'upcoming'
  }

  showFailed (e) {
    this.switchView(e)
    this.failedTarget.classList.remove('hidden')
    window.currentRequestSection = 'failed'
  }
}
