import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ['connectedElement', 'notConnectedElement', 'fragment']

  connect () {
    if (document.hidden) {
      return false
    }

    const controller = this

    this.subscription = consumer.subscriptions.create('ClipboardChannel', {
      connected: function () {
        controller.displayConnectionStatus('connected')
      },

      disconnected: function () {
        controller.displayConnectionStatus('disconnected')
      },

      received: function (data) {
        if (data === 'update!') {
          controller.liveReload()
        }
      }
    })
  }

  liveReload () {
    const params = encodeURIComponent(JSON.stringify(this.fragmentTargets.map((target) => {
      return { partial: target.dataset.partial, id: target.id }
    })))

    return Rails.ajax({
      type: 'GET',
      url: `/retrieve_fragments?data=${params}`,
      success: (_) => {
        this.displayConnectionStatus('connected')
        this.restoreActiveTab()
        this.restoreCurrentRequestView()
        this.restoreOpenMenu()
      }
    })
  }

  displayConnectionStatus (status) {
    const emptyStateVisible = this.hasConnectedElementTarget

    if (status === 'connected' && emptyStateVisible) {
      this.connectedElementTarget.classList.remove('hidden')
      this.notConnectedElementTarget.classList.add('hidden')
    } else if (status === 'disconnected' && emptyStateVisible) {
      this.connectedElementTarget.classList.add('hidden')
      this.notConnectedElementTarget.classList.remove('hidden')
    }
  }

  restoreOpenMenu () {
    const openMenu = document.querySelector(`#${window.openMenu}`)

    if (openMenu) {
      openMenu.classList.remove('hidden')
    }
  }

  restoreActiveTab () {
    const activeTab = document.querySelector(`.tab#${window.activeTab}`)
    const activeTabContent = document.querySelector(`#${window.activeTab}_sessions`)

    if (activeTab) {
      document.querySelectorAll('.tab').forEach((element) => {
        element.classList.remove('active')
      })

      document.querySelectorAll('.tabContent').forEach((element) => {
        element.classList.remove('active')
      })

      activeTab.classList.add('active')
      activeTabContent.classList.add('active')
    }
  }

  restoreCurrentRequestView () {
    if (window.currentRequestSection) {
      ['.activeRequests', '.upcomingRequests', '.failedRequests'].forEach((className) => {
        document.querySelector(`#incomingRequests ${className}`).classList.add('hidden')
      })

      document.querySelector(`#incomingRequests .${window.currentRequestSection}Requests`)
        .classList
        .remove('hidden')
    }
  }
}
