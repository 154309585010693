import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabContent']

  selectTab (e) {
    e.preventDefault()

    const activeTab = e.currentTarget
    const activeTabContent = document.querySelector(`ul#${e.currentTarget.id}_sessions`)

    this.tabTargets.forEach((tab) => {
      tab.classList.remove('bg-blue-900', 'text-white')
      tab.classList.add('text-blue-900')
    })

    this.tabContentTargets.forEach((tab) => {
      tab.classList.add('hidden')
    })

    activeTab.classList.add('bg-blue-900', 'text-white')
    activeTab.classList.remove('text-blue-900')
    activeTabContent.classList.remove('hidden')

    window.activeTab = activeTab.id
  }
}
