import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['mainNav', 'avatar', 'secondaryMenu', 'selectMenu']

  toggleNavMenu (e) {
    e.preventDefault()
    e.stopPropagation()
    this.mainNavTarget.classList.toggle('hidden')
    this.avatarTarget.classList.toggle('active')
  }

  navLinkClick (e) {
    if (e.target.dataset.method === 'delete') {
      Rails.ajax({
        url: '/sign_out',
        type: 'DELETE'
      })
    } else {
      window.location = e.target.href
    }
  }

  closeAllMenus (e) {
    if (e.target.dataset.globalMenuIgnore || e.target.closest('[data-global-menu-ignore]')) {
      return false
    }

    if (this.hasMainNavTarget) {
      this.mainNavTarget.classList.add('hidden')
      this.avatarTarget.classList.remove('active')
      this.secondaryMenuTargets.forEach((element) => {
        element.classList.add('hidden')
      })
    }

    if (this.hasSelectMenuTarget) {
      this.selectMenuTarget.classList.add('hidden')
    }
  }
}
