import { Controller } from '@hotwired/stimulus'
import moment from 'moment'
import daterangepicker from 'daterangepicker'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["dateInput", "start", "end"]

  connect () {
    this.startDate = moment(this.startTarget.innerHTML)
    this.endDate = moment(this.endTarget.innerHTML)
  
    $('#reportrange').daterangepicker({
      startDate: this.startDate,
      endDate: this.endDate,
      showCustomRangeLabel: false,
      alwaysShowCalendars: true,
      opens: 'center',
      autoApply: true,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, this.setDate(this.startDate, this.endDate));
    
    let handleChange = this.handleChange
    let dateInput = this.dateInputTarget

    $('#reportrange').on('apply.daterangepicker', function(ev, picker) {
      this.startDate = picker.startDate
      this.endDate = picker.endDate
      handleChange(dateInput)
    });
  }

  setDate(start, end) {
    $('#reportrange').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
  }

  handleChange(input) {
    const form = input.closest("form");
    const timeline = document.getElementById("timeline");

    timeline.innerHTML = "<p class='p-2 text-lg text-center text-gray-400 loading'>Please wait, we're building your timeline</p>"
    Rails.fire(form, "submit");

  }
}
