import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.liveReloadController.connect()
  }

  get liveReloadController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('#marinaDashboard'), 'live-reload')
  }
}
