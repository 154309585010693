import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'markPaidButton',
    'markVoidButton',
    'markPaidForm',
    'markVoidForm',
    'showDeliverForm',
    'showReminderForm',
    'showLateFeeForm',
    'showIssueCreditForm',
    'lineItemForm',
    'lineItemsList',
    'lineItem',
    'total',
    'managementLinks'
  ]

  showDeliverModal (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.managementLinksTarget.classList.add("hidden")
    this.showDeliverFormTarget.classList.remove("hidden")
    
  }

  showReminderModal (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.managementLinksTarget.classList.add("hidden")
    this.showReminderFormTarget.classList.remove("hidden")
    
  }

  showIssueCreditModal (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.managementLinksTarget.classList.add("hidden")
    this.showIssueCreditFormTarget.classList.remove("hidden")
    
  }

  showLateFeeModal (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.managementLinksTarget.classList.add("hidden")
    this.showLateFeeFormTarget.classList.remove("hidden")
    
  }

  showMarkVoidModal (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.managementLinksTarget.classList.add("hidden")
    this.markVoidFormTarget.classList.remove("hidden")
    
  }

  showMarkPaidModal (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.managementLinksTarget.classList.add("hidden")
    this.markPaidFormTarget.classList.remove("hidden")
    
  }

  showNewLineItemForm (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    var new_form = this.lineItemFormTarget.innerHTML
    
    this.lineItemsListTarget.insertAdjacentHTML('beforeend', new_form)
    
  }
  
  updateUnitPrice (e) {

    this.updateTotal()
  }
  
  updateTotal (e) {
    var total = 0
    for (let i = 0; i < this.lineItemTargets.length; i++) {
      
      var qty = parseInt(this.lineItemTargets[i].querySelector('input#quantity').value)
      var unit_price_in_cents = parseFloat(parseFloat(this.lineItemTargets[i].querySelector('input#unit_price').value*100).toFixed(2))
      
      if (qty && unit_price_in_cents) {
        total += qty*unit_price_in_cents
      }
    }
    
    this.totalTarget.innerHTML = "$" + (total/100).toFixed(2)
  }
  
  hideModal (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.managementLinksTarget.classList.remove("hidden")
    this.markPaidFormTarget.classList.add("hidden")
    this.markVoidFormTarget.classList.add("hidden")
    this.showDeliverFormTarget.classList.add("hidden")
    this.showReminderFormTarget.classList.add("hidden")
    this.showLateFeeFormTarget.classList.add("hidden")
    
  }

}
