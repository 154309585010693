import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectMenu', 'animation', 'tab', 'link']

  showSortOptions () {
    this.selectMenuTarget.classList.toggle('hidden')

    if (this.selectMenuTarget.classList.contains('hidden')) {
      window.openMenu = ''
    } else {
      window.openMenu = this.selectMenuTarget.id
    }
  }

  loading () {
    this.animationTargets.forEach(function (target) {
      target.style.display = 'block'
    })
  }

  selectTab (e) {
    var currentTab = e.currentTarget.id

    this.linkTargets.forEach(function (link) {
      link.href = link.href.replace(/active_tab.*&/, `active_tab=${currentTab}&`)
    })
  }
}
