import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['option', 'input']

  setSelectedOption (e) {
    e.preventDefault()
    e.stopPropagation()

    this.optionTargets.forEach((target) => {
      target.classList.remove('selected')
    })
    e.currentTarget.classList.add('selected')
    this.inputTarget.value = e.currentTarget.dataset.value
  }
}
