import { Controller } from '@hotwired/stimulus'
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";

export default class extends Controller {
  static targets = ["saveSlipForm"]

  initialize() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYWlydHdvIiwiYSI6ImNrenNuaDQ1ajA2ZDYydXA5M213OWZkOWcifQ.OwKNZ2B1fV-irCmEXjV6fg';
  }
  
  connect() {
    this.buildMap()
  }
  
  buildMap() {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/airtwo/ckzso68m7000a15pne56ycfcu',
      center: [-80.888630, 35.487700],
      zoom: 17,
      attributionControl: false
    });
    
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true
      },
      defaultMode: 'simple_select'
    });
    
    map.addControl(draw, 'bottom-left');
    
    map.on('load', function() {
      const dataUrl = window.location.origin + "/api/slips/" + document.getElementById('map').dataset.marinaId
      
      // Add a data source containing GeoJSON data.
      map.addSource('slips', {
        'type': 'geojson',
        'data': dataUrl,
        'generateId': true
      });
      
      // Add a new layer to visualize the polygon.
      map.addLayer({
        'id': 'slips-layer',
        'type': 'fill',
        'source': 'slips', // reference the data source
        'layout': {},
        'paint': {
          'fill-color': [
              'case',
              ['==',['get', 'isCurrent'], true],
              '#22c55e', // if selected true, paint in blue
              ['==',['get', 'isCurrent'], false],
              '#ef4444', // if selected true, paint in blue
              ['==',['get', 'boatId'], null],
              '#f1f5f9', // if selected true, paint in blue
              '#94a3b8' // else paint in gray
          ],
          'fill-opacity': [
              'case',
              ['boolean',['feature-state', 'clicked'], false],
              0.8, // if selected true, paint in blue
              ['boolean',['feature-state', 'hover'], false],
              0.6,
              0.5
          ]
        }
      });
      
      // When a click event occurs on a feature in the states layer,
      // open a popup at the location of the click, with description
      // HTML from the click event's properties.
      var polygonClickID = null;
      var polygonHoverID = null;
      
      map.on('click', 'slips-layer', (e) => {
        map.getCanvas().style.cursor = 'pointer';
        console.log(e.features)
        
        if (e.features.length > 0) {
          if (polygonClickID) {
            map.removeFeatureState({
              source: "slips",
              id: polygonClickID
            });
          }
        
          polygonClickID = e.features[0].id;
        
          map.setFeatureState({
            source: 'slips',
            id: polygonClickID,
          }, {
            clicked: true
          });
          
          map.flyTo({
            center: e.lngLat,
            zoom: 20
          });
        }
        
        if (e.features[0].properties.boatId) {
          const htmlBlock = "<div class='bg-white p-4 shadow-lg rounded-lg'><div class='flex justify-between border-b pb-2 mb-2'><h1 class='text-4xl font-bold px-2 font-sans'>"+e.features[0].properties.name+"</h1><p class='text-sm text-gray-600 px-2'>"+e.features[0].properties.dimensions+"</p></div><p class='text-lg px-2'>"+e.features[0].properties.customerName+"</p><p class='text-sm text-gray-600 px-2'>Current balance: "+e.features[0].properties.balance+"</p><p class='text-lg p-2'><div class='flex items-center'><a href='/boats/"+e.features[0].properties.boatId+"' target='_blank' class='text-blue-900 border border-blue-900 p-1 text-sm rounded-md'>Go to boat</a></p><p class='text-lg p-2'><a href='' id='editSlipDimensions' data-action='click->map#editSlipDimensions' data-slip-id='"+e.features[0].properties.id+"' data-polygon-id='"+e.features[0].id+"' data-geometry='"+JSON.stringify(e.features[0].geometry.coordinates)+"'class='text-blue-900 border border-blue-900 p-1 text-sm rounded-md'>Edit Slip</a></p></div></div>"
          
          let popup = new mapboxgl.Popup({maxWidth: 'auto'})
          .setLngLat(e.lngLat)
          .setHTML(htmlBlock)
          .addTo(map);
          
          popup.on('close', function(e) {
            map.setFeatureState({
              source: "slips",
              id: polygonClickID
            }, {
              clicked: false
            });
          })
          
        } else {
          const htmlBlock = "<div class='bg-white p-4 shadow-lg rounded-lg'><h1 class='text-4xl font-bold px-2 font-sans'>"+e.features[0].properties.name+"</h1><p class='text-lg px-2 border-b'>This slip is empty</p><p class='text-lg p-2'><a href='' id='editSlipDimensions' data-action='click->map#editSlipDimensions' data-slip-id='"+e.features[0].properties.id+"' data-polygon-id='"+e.features[0].id+"' data-geometry='"+JSON.stringify(e.features[0].geometry.coordinates)+"'class='text-blue-900 border border-blue-900 p-1 text-sm rounded-md'>Edit Slip</a></p></div>"
          
          let popup = new mapboxgl.Popup({maxWidth: 'auto'})
          .setLngLat(e.lngLat)
          .setHTML(htmlBlock)
          .addTo(map);
          
          popup.on('close', function(e) {
            map.setFeatureState({
              source: "slips",
              id: polygonClickID
            }, {
              clicked: false
            });
          })
        }
          

      });
      
      // Change the cursor to a pointer when
      // the mouse is clicked on the slips layer.
      map.on('mouseenter', 'slips-layer', (e) => {
        map.getCanvas().style.cursor = 'pointer';
        if (polygonHoverID) {
          map.setFeatureState(
            { source: 'slips', id: polygonHoverID },
            { hover: false }
          );
        }
        polygonHoverID = e.features[0].id;
        
        map.setFeatureState(
          {
            source: 'slips',
            id: polygonHoverID
          },
          {
            hover: true
          }
        );
        
      });
       
      // Change the cursor back to a pointer
      // when it leaves the slips layer.
      map.on('mouseleave', 'slips-layer', (e) => {
        map.getCanvas().style.cursor = '';
        
        if (polygonHoverID !== null) {
          map.setFeatureState(
            { source: 'slips', id: polygonHoverID },
            { hover: false }
          );
        }
        polygonHoverID = null;

      });
      
      
      document.addEventListener('keypress', logKey);
      
      function logKey(e) {
        if (e.key == "d") {
          draw.changeMode("draw_polygon");
          const popup = document.getElementsByClassName('mapboxgl-popup');
          if ( popup.length ) {
            popup[0].remove();
          }
        }
      }
      
    });
    
    
    this.draw = draw
    this.map = map
    this.map.on('draw.create', this.updateArea);
    this.map.on('draw.delete', this.updateArea);
    this.map.on('draw.update', this.updateArea);
  }
  
  editSlipDimensions(e) {
    e.preventDefault()

    let id = parseInt(e.target.dataset.slipId)

    this.map.setFilter('slips-layer', ['!=', ['get', 'id'], id]);
    
    this.draw.add({
        'id': e.target.dataset.slipId,
        'type': 'Feature',
        'properties': {},
        'geometry': {
            'type': 'Polygon',
            'coordinates': JSON.parse(e.target.dataset.geometry)
        }
    });
    
    let slipCoords = JSON.parse(e.target.dataset.geometry)
    
    document.getElementById('slipIdInput').value = e.target.dataset.slipId
    document.getElementById('editSlipForm').classList.remove('hidden')
    document.getElementById('updatedCoordInput').value = JSON.stringify(slipCoords[0])
    
  }

  updateArea(e) {
    console.log(e)
    // const data = draw.getAll();
    // console.log(data)
    
    if (e.type == 'draw.create') {
      let form = document.getElementById('saveSlipForm')
      let coordInput = document.getElementById('coordInput')
      
      coordInput.value = JSON.stringify(e.features[0].geometry.coordinates[0])
      form.classList.remove('hidden')
    } else if (e.type == 'draw.update') {
      let form = document.getElementById('editSlipForm')
      let coordInput = document.getElementById('updatedCoordInput')
      
      coordInput.value = JSON.stringify(e.features[0].geometry.coordinates[0])
      form.classList.remove('hidden')
    }
  }

}
