import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['checkbox']

  toggle (e) {
    e.preventDefault()
    this.animateSwitch()
    Rails.ajax({
      url: e.currentTarget.dataset.path,
      type: 'PUT'
    })
  }

  animateSwitch () {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
  }
}
