import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['monthlyPrice', 'annualPrice']

  calculatePrice (e) {
    const boatCount = e.currentTarget.value

    if (boatCount) {
      this.monthlyPriceTarget.textContent = boatCount * 3
      this.annualPriceTarget.textContent = ((boatCount * 3) * 0.80).toFixed(2)
    } else {
      this.monthlyPriceTarget.innerHTML = '&mdash;'
      this.annualPriceTarget.innerHTML = '&mdash;'
    }
  }
}
