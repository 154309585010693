import { Controller } from '@hotwired/stimulus'
import Awesomplete from 'awesomplete'

export default class extends Controller {
  static targets = ['input', 'container']

  initialize () {
    const awesomplete = new Awesomplete(this.inputTarget)
    awesomplete.list = this.list
  }

  get list () {
    return window.gon.autocomplete_list
  }
}
