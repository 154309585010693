import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "previousOccupants",
    "slipMoveInForm"
    
  ]

  showSlipMoveInForm(e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.slipMoveInFormTarget.classList.remove("hidden")
    e.target.classList.add("hidden")
  }

  showPreviousOccupations(e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    
    this.previousOccupantsTarget.classList.remove("hidden")
    e.target.classList.add("hidden")
  }

}
