import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["notesInput"]
  
  connect() {
    this.timeout
  }
  

  capture() {

    clearTimeout(this.timeout);
    
    this.timeout = setTimeout(this.updateLease.bind(this), 800);
      
  }
  
  updateLease() {
    console.log(this.notesInputTarget.value)
    
    let id = this.notesInputTarget.dataset.id
    
    fetch(`/leases/${id}/update_notes`, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector("[name='csrf-token']").content,
      },
      body: JSON.stringify({
        'notes': this.notesInputTarget.value,
        
      }),
    })
  }

}
