import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'slipNumber',
    'form',
    'input',
    'scheduledLineItem',
    'iceLineItem',
    'fuelLineItem',
    'directionOption',
    'directionInput',
    'openButton',
    'closeButton'
  ]

  toggleForm (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    this.formTarget.classList.toggle('hidden')
    this.openButtonTarget.classList.toggle('hidden')
    this.closeButtonTarget.classList.toggle('hidden')
    this.slipNumberTarget.focus()
  }

  toggleScheduled (e) {
    e.preventDefault()
    this.scheduledLineItemTarget.classList.toggle('hidden')
  }

  toggleIce (e) {
    e.preventDefault()
    this.iceLineItemTarget.classList.toggle('hidden')
  }

  toggleFuel (e) {
    e.preventDefault()
    this.fuelLineItemTarget.classList.toggle('hidden')
  }

  toggleChecked (e) {
    const checkbox = e.currentTarget.querySelector('input[type=checkbox]')
    checkbox.checked = !checkbox.checked // invert value
  }

  selectDirection (e) {
    e.preventDefault()
    this.directionOptionTargets.forEach((target) => {
      target.classList.remove('border-blue-500')
    })

    e.currentTarget.classList.add('border-blue-500')
    const selectedDirection = e.currentTarget.textContent.toLowerCase().trim()
    this.directionInputTarget.value = selectedDirection
  }
}
