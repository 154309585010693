import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['animation', 'buttons', 'secondaryMenu', 'onForks', 'pickupButton', 'arrow']

  // connect() {
  // const currentUserHasBoatOnForks = document.querySelector('[data-target="clipboard-row.buttons clipboard-row.onForks"]')
  // console.log(currentUserHasBoatOnForks)
  // if (this.hasPickupButtonTarget && currentUserHasBoatOnForks) {
  //   this.pickupButtonTarget.remove()
  // }
  // }

  loading () {
    this.animationTarget.style.display = 'block'
    this.buttonsTarget.style.display = 'none'

    document.querySelectorAll('.secondaryActions').forEach((element) => {
      element.classList.add('hidden')
    })
  }

  toggleSecondaryMenu (e) {
    e.preventDefault()
    e.stopPropagation()

    const open = this.secondaryMenuTarget.classList.contains('hidden')

    document.querySelectorAll('.secondaryActions').forEach((element) => {
      element.classList.add('hidden')
    })
    
    if (open) {
      this.secondaryMenuTarget.classList.remove('hidden')
      this.arrowTarget.classList.add('rotate-180');
    } else {
      this.arrowTarget.classList.remove('rotate-180');
    }
    

    window.openMenu = this.secondaryMenuTarget.id
  }
}
