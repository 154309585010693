// Entry point for the build script in your package.json
import { Turbo } from "@hotwired/turbo-rails";
import "@rails/activestorage"
import "@rails/actiontext"
import "trix"
import "chartkick/chart.js"
import "./controllers"
import "./direct_uploads"


require("@rails/ujs").start()

window.Turbo = Turbo;

// *** OLD ACTIONCABLE STUFF

// import ActionCable from '@rails/actioncable'

// window.CableApp = {}
// const CableApp = window.CableApp

// CableApp.cable = ActionCable.createConsumer('/cable')


window.reconnectClipboard = function () {
  const event = new window.Event('reconnectClipboard')
  window.dispatchEvent(event)
}
  
// *** END OLD ACTIONCABLE STUFF