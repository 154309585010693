import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['printButton']

  printPage (e) {
    e.preventDefault()
    window.print()
  }
}
