import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'tokenInput',
    'planInput',
    'plan',
    'changePlanForm',
    'submitButton'
  ]

  style = {
    base: {

    }
  }

  connect () {
    this.stripe = window.Stripe(document.querySelector("meta[name='stripe-key']").getAttribute("content"))
    const elements = this.stripe.elements()
    this.card = elements.create('card', { style: this.style })
    this.card.mount('#card-element')
  }

  async handleSubmit (e) {
    e.preventDefault()

    if (this.planInputTarget.value.length === 0 && this.data.get('planRequired')) {
      return false
    }

    const { token, error } = await this.stripe.createToken(this.card)

    if (error) {
      // Inform the customer that there was an error.
      const errorElement = document.getElementById('card-errors')
      errorElement.textContent = error.message
    } else {
      // Send the token to your server.
      this.stripeTokenHandler(token)
    }
  }

  stripeTokenHandler (token) {
    this.tokenInputTarget.setAttribute('value', token.id)

    this.formTarget.submit()
  }

  selectPlan (e) {
    this.planTargets.forEach((target) => {
      target.classList.remove('border-sky-500', 'border-2')
    })

    this.card.focus()

    e.currentTarget.classList.add('border-sky-500', 'border-2')
    this.planInputTargets.forEach((target) => {
      console.log(e.currentTarget.dataset.plan)
      target.value = e.currentTarget.dataset.plan
    })

    if (this.hasChangePlanFormTarget) {
      this.changePlanFormTarget.classList.remove('hidden')
    }

    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.value = 'Submit Payment'
  }
}
